import BasePlugin from '../BasePlugin'

export default class CollectForecastReports extends BasePlugin {
  async execute () {
    // this.context.getDashboardComponents()['component_284e13e6-c4d9-4568-b7b1-13df5f080e71'][0] //startDate
    // this.context.getDashboardComponents()['component_7d7494b1-a28c-4ae9-a30e-4839dc12b008'][0] //endDate
    // this.context.getDashboardComponents()['component_99a11415-0bde-4d58-94b1-d90ee6785a2f'][0] //deadline
    let card = this.context.getDashboardComponents()['component_284e13e6-c4d9-4568-b7b1-13df5f080e71'][0].getModel()
    let attributes = {
      'deadline': card['deadline'],
      'period_from': card['period_from'],
      'period_to': card['period_to']
    }
    let idsObject = this.context.getDashboardComponents()['component_ea05bfdc-ad0f-4a39-840d-ca5fdd8bc0cc'][0]['$refs']['table']['selected']
    let ids = []
    idsObject.forEach(function (item) {
      ids.push(item.id)
    })
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/ReportForecastProject/CollectForecastReportsCommand`,
      { 'attributes': attributes, 'projectIds': ids },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getParentContext().getDashboardComponents()['component_d170081f-9c9c-408d-ba26-b57005416fdc'][0].loadData()
      this.context.$msgbox.close()
    })
  }
}
